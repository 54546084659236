import React from 'react';

const Contact = () => {
  return (
    <div className="min-h-screen p-8 font-sans flex items-center justify-center" style={{background: 'linear-gradient(135deg, #A4C8FF, #D9D9D9)', color: 'black'}}>
      <div className="text-center">
        <h1 className="text-5xl font-bold mb-8">Contact Information</h1>
        <h2 className="text-2xl font-bold mb-4">Email</h2>
        <p className="mb-6">
          <a href="mailto:halp@thenoplace.com" className="text-blue-500 hover:underline">help@preggomom.com</a>
        </p>
      </div>
    </div>
  );
};

export default Contact;
