import React from 'react';

const TeaLanding = () => {
  const handlePreDownload = () => {
    window.location.href = 'http://localhost:3000/';
  };

  return (
    <div className="relative min-h-screen p-4 overflow-hidden text-black" style={{background: 'linear-gradient(135deg, #A4C8FF, #D9D9D9)'}}>
      {/* Content Wrapper */}
      <div className="relative z-10">
        {/* Header */}
        <header className="flex justify-between items-center mb-8">
          <div className="flex items-center">
            <img src="/pacify.png" alt="Preggo" className="h-8 w-8 mr-2" />
            <span className="text-xl font-bold">Preggo</span>
          </div>
        </header>

        {/* Main Content */}
        <main className="flex flex-col items-center justify-center text-center my-12">
          <h1 className="text-5xl font-bold mb-4">
            <span>Your Pregnancy Journey,</span><br />
            <span style={{ display: 'block', marginTop: '0.5rem' }}>Supported Every Step</span>
          </h1>
          <div className="p-2 rounded-lg mb-4" style={{ background: 'transparent' }}>
            <img src="/black.png" alt="Download Preggo" className="w-32 h-32" style={{ backgroundColor: 'transparent' }} />
          </div>
          <p className="text-xl mb-8">Coming Fall 2024!!</p>
          <button 
            className="bg-green-400 text-black font-bold rounded-full mb-4 hover:bg-green-500 transition duration-300"
            onClick={handlePreDownload}
            style={{ width: '350px', height: '65px', fontSize: '24px' }}
          >
            PRE-DOWNLOAD NOW
          </button>
        </main>

        {/* Footer */}
        <footer className="text-xs mt-8">
          <div className="flex flex-wrap justify-center space-x-4 text-sm">
            <a href="/terms-of-service" className="hover:text-gray-300 transition duration-300">Terms of Service</a>
            <a href="/privacy-policy" className="hover:text-gray-300 transition duration-300">Privacy Policy</a>
            <a href="/contact" className="hover:text-gray-300 transition duration-300">Contact Us</a>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default TeaLanding;
