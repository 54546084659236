import React from 'react';

const TermsOfService = () => {
  return (
    <div className="min-h-screen p-8 font-sans" style={{ background: 'linear-gradient(135deg, #A4C8FF, #D9D9D9)', color: 'black' }}>
      <div className="max-w-4xl mx-auto bg-transparent p-8">
        <h1 className="text-4xl font-bold mb-6">Terms and Conditions</h1>
        <p className="mb-4">Last updated: July 04, 2024</p>
        <p className="mb-6">Please read these terms and conditions carefully before using Our Service.</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Interpretation and Definitions</h2>
        <h3 className="text-xl font-semibold mt-6 mb-3">Interpretation</h3>
        <p className="mb-4">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

        <h3 className="text-xl font-semibold mt-6 mb-3">Definitions</h3>
        <p className="mb-4">For the purposes of these Terms and Conditions:</p>
        <ul className="list-disc pl-6 mb-6">
          <li className="mb-2"><strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device, named Tea</li>
          <li className="mb-2"><strong>Application Store</strong> means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.</li>
          <li className="mb-2"><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
          <li className="mb-2"><strong>Country</strong> refers to: Texas, United States</li>
          <li className="mb-2"><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Tea.</li>
          <li className="mb-2"><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
          <li className="mb-2"><strong>Service</strong> refers to the Application.</li>
          <li className="mb-2"><strong>Terms and Conditions</strong> (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</li>
          <li className="mb-2"><strong>Third-party Social Media Service</strong> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</li>
          <li className="mb-2"><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Acknowledgment</h2>
        <p className="mb-4">These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</p>
        <p className="mb-4">Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.</p>
        <p className="mb-4">By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.</p>
        <p className="mb-4">You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>
        <p className="mb-6">Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>

        {/* Continue with the rest of the sections, following the same formatting pattern */}

        <h2 className="text-2xl font-semibold mt-8 mb-4">Contact Us</h2>
        <p className="mb-4">If you have any questions about these Terms and Conditions, You can contact us:</p>
        <ul className="list-disc pl-6 mb-6">
          <li className="mb-2">By visiting this page on our website: <a href="https://preggomom.com/contact" rel="external nofollow noopener" target="_blank" className="underline">https://preggomom.com/contact</a></li>
        </ul>
      </div>
    </div>
  );
};

export default TermsOfService;
