import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TeaLanding from './TeaLanding';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import Contact from './Contact';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<TeaLanding />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;